import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import styled from "styled-components"

import Title from "../components/Title"
import { Layout, Seo } from "../components"

export const query = graphql`
  {
    datoCmsImage(image: { alt: { eq: "Vermont St.Alban Court House" } }) {
      image {
        alt
        gatsbyImageData
      }
    }
  }
`

const NotFoundPage = ({ data }) => {
  const { datoCmsImage: image } = data

  const seoImage = image.image.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <Seo
        title="Page Not Found"
        description="Page Not Found"
        image={seoImage}
      />
      <Wrapper className="section page-header">
        <Container>
          <div className="page-container">
            <Row>
              <Col>
                <Title title="Page Not Found" />
              </Col>
            </Row>
          </div>

          <Row>
            <Col>
              <Link to="/">Go home</Link>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;

  .page-container {
    margin-top: 5.5rem;
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    text-align: center;
  }
`

export default NotFoundPage
